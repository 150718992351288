/** @jsxImportSource @emotion/react */
import React from 'react';
import { css } from '@emotion/react';
import { Tooltip } from '@mui/material';
import ShareIcon from '../../../components/images/Share.png';
import InfoIcon from '../../../components/Single/InfoIcon';

const previewStyles = css`
  padding: 20px;
  background-color: white;
  border: 1px solid #e6e6e6;
  border-radius: 10px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  text-align: center;
`;

const profileImageStyles = css`
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin-bottom: 10px;
  object-fit: cover;
`;

const businessNameStyles = css`
  color: #000;
  font-size: 16px;
  margin: 5px 0;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
`;

const businessAccountBoxStyles = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background-color: #f9f9f9;
  border-top: 1px solid #e6e6e6;
  border-bottom: 1px solid #e6e6e6;
  margin-bottom: 20px;
`;

const infoTextStyles = css`
  text-align: left;
  font-size: 14px;
  color: #555;
  margin-bottom: 10px;
`;

const aboutTextStyles = css`
  ${infoTextStyles}
  white-space: pre-wrap;
  word-break: break-word;
  max-height: 150px;
  overflow-y: auto;
  padding-right: 10px;
`;

const tooltipStyles = css`
  & .MuiTooltip-tooltip {
    white-space: pre-line;
    font-size: 14px;
  }
`;

const BusinessPreview = ({ formData, importedImage }) => {
  const getImageSrc = () => {
    if (importedImage instanceof File) {
      return URL.createObjectURL(importedImage);
    } else if (typeof importedImage === 'string') {
      return importedImage;
    } else if (formData.profilePicture) {
      return formData.profilePicture;
    }
    return null;
  };

  const imageSrc = getImageSrc();

  return (
    <div css={previewStyles}>
      <div css={css`text-align: center; margin-bottom: 10px;`}>
        {imageSrc ? (
          <img
            css={profileImageStyles}
            src={imageSrc}
            alt="Profile"
          />
        ) : (
          <div css={[profileImageStyles, css`background-color: #ddd; display: inline-block;`]}></div>
        )}
      </div>

      <div css={businessNameStyles}>
        Sendue
        <Tooltip 
          title={
            <div>
              <div>This is the name the Recipient will see</div>
              <div>to show custom name</div>
              <div>contact us for <strong>Enterprise</strong> subscription</div>
            </div>
          }
          arrow
          css={tooltipStyles}
          PopperProps={{
            sx: {
              '& .MuiTooltip-tooltip': {
                backgroundColor: '#616161',
                padding: '8px 12px',
                fontSize: '14px',
                maxWidth: 'none'
              }
            }
          }}
        >
          <div css={css`
            cursor: pointer;
            background: #E8E8E8;
            border-radius: 50%;
            width: 20px;
            height: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
          `}>
            <InfoIcon />
          </div>
        </Tooltip>
      </div>

      <img
        src={ShareIcon}
        alt="Share Icon"
        css={css`width: 24px; height: 24px; margin-bottom: 2px; margin-top: 20px;`}
      />

      <p css={css`font-size: 14px; color: #008069; margin-top: 0; margin-bottom: 20px;`}>
        Share
      </p>

      <div css={businessAccountBoxStyles}>
        <p css={css`font-size: 14px; color: #555; margin: 0;`}>This is a business account.</p>
        <img src={InfoIcon} alt="Info Icon" css={css`width: 16px; height: 16px;`} />
      </div>

      <div css={infoTextStyles}>
        <strong>About:</strong>
        <div css={aboutTextStyles}>{formData.about || 'About the business...'}</div>
      </div>
      
      {formData.location && <p css={infoTextStyles}><strong>Location:</strong> {formData.location}</p>}
      {formData.email && <p css={infoTextStyles}><strong>Email:</strong> {formData.email}</p>}
      {formData.website && <p css={infoTextStyles}><strong>Website:</strong> {formData.website}</p>}
      {formData.facebookLink && <p css={infoTextStyles}><strong>Facebook:</strong> {formData.facebookLink}</p>}
      {formData.instagramLink && <p css={infoTextStyles}><strong>Instagram:</strong> {formData.instagramLink}</p>}
    </div>
  );
};

export default BusinessPreview;