import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import CustomTable from '../../../components/Single/CustomTable';
import ActionButton from '../../../components/Single/ActionButton';
import campaignAPI from '../../../service/campaignAPI';
import { setLoading } from '../../../store/campaignSlice';
import { selectIsLoading, selectAuthToken } from '../../../store/selectors';
import { logout } from '../../../store/authSlice';
import styled from '@emotion/styled';

const CampaignContainer = styled.div`
  padding: 20px;
  position: relative;
  min-height: 100vh;
`;

const Title = styled.h1`
  margin-bottom: 20px;
`;

const TableButtonContainer = styled.div`
  background-color: rgba(255, 255, 255, 0);
  position: relative;
  width: 95%;
  max-width: 1400px;
  margin: 0 auto;
  padding: 20px;
  border-radius: 10px;
`;

const CampaignContent = styled.div`
  background-color: white;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  margin-bottom: 60px;
`;

const ErrorMessage = styled.div`
  color: red;
  text-align: center;
  margin-bottom: 20px;
`;

const Campaign = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const token = useSelector(selectAuthToken);
  const [campaigns, setCampaigns] = useState([]);
  const [error, setError] = useState(null);
  const isLoading = useSelector(selectIsLoading);

  useEffect(() => {
    if (token) {
      fetchCampaigns();
    }
    return () => {
      dispatch(setLoading(false));
    };
  }, [token, dispatch]);
  
  const fetchCampaigns = async () => {
    dispatch(setLoading(true));
    setError(null);
    try {
      const response = await campaignAPI.fetchCampaigns(token);
      setCampaigns(response);
    } catch (err) {
      console.error('Error fetching campaigns:', err);
      if (err.response && err.response.status === 401) {
        dispatch(logout());
        navigate('/login');
      } else {
        setError('Failed to fetch campaigns. Please try again.');
      }
    } finally {
      dispatch(setLoading(false));
    }
  };

  const headers = ['Name', 'Status', 'Recipients', 'Sent', 'Read', 'Error', 'Unsubscribe', 'Scheduled', 'Last modified'];
  
  const formatCampaignData = (campaigns) => {
    if (!campaigns || !Array.isArray(campaigns)) {
      return [];
    }
    return campaigns.map(campaign => [
      campaign.name,
      campaign.status,
      campaign.recipients,
      campaign.how_many_sent,
      campaign.read_count,
      campaign.error,
      campaign.unsubscribed,
      campaign.scheduled ? new Date(campaign.scheduled).toLocaleString() : 'Not scheduled',
      new Date(campaign.last_modified).toLocaleString()
    ]);
  };

  const handleCreateCampaign = () => {
    navigate('/campaign/steps/leads');
  };

  return (
    <CampaignContainer>
      <Title>Campaigns</Title>
      
      {error && <ErrorMessage>{error}</ErrorMessage>}
      
      <CampaignContent>
        <CustomTable 
          headers={headers} 
          data={formatCampaignData(campaigns)} 
          emptyMessage={isLoading ? "Loading campaigns..." : "You have not created any campaign yet."} 
        />
      </CampaignContent>

      <TableButtonContainer>
        <ActionButton 
          text="Create Campaign"
          onClick={handleCreateCampaign}
        />
      </TableButtonContainer>
    </CampaignContainer>
  );
};

export default Campaign;