import React from 'react';

const Footer = () => {
  const footerStyle = {
    backgroundColor: '#f8f8f8',
    borderTop: '1px solid #e7e7e7',
    padding: '20px 0',
    width: '100%',
  };

  const footerContentStyle = {
    maxWidth: '1200px',
    margin: '0 auto',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0 20px',
  };

  const paragraphStyle = {
    margin: 0,
    color: '#666',
  };

  const navStyle = {
    display: 'flex',
  };

  const linkStyle = {
    color: '#666',
    textDecoration: 'none',
    marginLeft: '20px',
  };

  return (
    <footer style={footerStyle}>
      <div style={footerContentStyle}>
        <p style={paragraphStyle}>&copy; 2024 Sendue. All rights reserved.</p>
        <nav style={navStyle}>
          <a href="/privacy" style={linkStyle}>Privacy Policy</a>
          <a href="/terms" style={linkStyle}>Terms of Service</a>
          <a href="/contact" style={linkStyle}>Contact Us</a>
        </nav>
      </div>
    </footer>
  );
};

export default Footer;